.App {
  text-align: center;

}
.Loading {
  height: 30vmin;
  pointer-events: none;
}
.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.Progress{
  width:100%;
  max-width:30vmin;
  height: 1vmin;  
  background:#cacaca73;
  border-width:2px;
  border-radius:20px;
  z-index:0;
}
.Bar{
  background:#ffffff;
  
  height:100%;
  border-radius:20px;
  width: 99%;
  position:relative;
}

@media screen {
/*  .App-logo {
    animation: App-logo-spin infinite 1s steps(8); 
  }
  */
  /*.Progress {
    animation: Fade-in 1s;
  }*/
  .Bar{
    animation: Bar-fill 20s cubic-bezier(0.4, 0.1, 0.1, 0.4);
    
  }
  .App-header{
    opacity:0;
    animation: Fade-in 22s ;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: url("./assets/wave.svg");
  background-size: 300% 100%;
  background-position: center;
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes Fade-in {
  from {
    opacity: 0;
  }
  10% {
    opacity:1;
  }
  90% {
    opacity:1;
  }
  to {
    opacity :0;
  }
}

@keyframes Bar-fill {
  from {
    width:10%;
  }
  30% {
    width:20%;
  }
  40% {
    width:40%;
  }
  50% {
    width:80%;
  }
  70% {
    width:95%;
  }
  80% {
    width:97%;
  }
  
  to {
    width:99%;
  }
}

